import utils from './lib/utils.js'
import ISI from './lib/isi.js'
import SiteMenu from './lib/site-menu.js'

if (utils.getParam('screenshot')) {
  document.body.classList.add('screenshot')
}

ISI()
SiteMenu()
