import utils from './utils.js'
import s from '@jamestomasino/scroll-frame'
import r from '@jamestomasino/resize-frame'

const ISI = function() {

  const isi = document.getElementById('important-safety-information')
  const sidebar = document.getElementById('isi-sidebar')

  function onMove() {
    const y = window.pageYOffset
    const h = Math.max(document.documentElement.clientHeight,window.innerHeight || 0)
    const bodyRect = document.body.getBoundingClientRect()
    const elemRect = isi.getBoundingClientRect()
    const offset = elemRect.top - bodyRect.top
    if (y > offset - h * 0.8) {
      isi.classList.remove('fixed')
      sidebar.classList.add('back-to-top')
    } else {
      isi.classList.add('fixed')
      sidebar.classList.remove('back-to-top')
    }
    if (y < 5) {
      sidebar.classList.add('top')
    } else {
      sidebar.classList.remove('top')
    }
  }

  function onResize() {
    const h = Math.max(document.documentElement.clientHeight,window.innerHeight || 0)
    // Update vh property
    const vh = h * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    // Call onMove if not in screenshot mode
    if (!utils.getParam('screenshot')) {
      onMove()
    }
  }

  document.querySelectorAll('.isi-toggle').forEach(btn => {
    btn.addEventListener('click', () => {
      if (isi.classList.contains('fullscreen')) {
        isi.classList.remove('fullscreen')
        // modalClose('isifullscreen')
      } else {
        isi.classList.add('fullscreen')
        // modalOpen('isifullscreen')
      }
    })
  })

  if (isi) {
    // Ignore scroll in screenshot mode
    if (!utils.getParam('screenshot')) {
      s.addScrollListener(onMove) // onMove will be called upon scroll.
    }
    r.addResizeListener(onResize) // onMove will be called upon resize.
  }
}

export default ISI
