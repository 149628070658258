const SiteMenu = function() {

    const mainNav = document.querySelector('.main-nav')
    if (mainNav) {
        // Get path on load and mark active navigation menus
        mainNav.querySelectorAll('a').forEach(a => {
            if (a.getAttribute('href') === document.location.pathname) {
              a.classList.add('active')
            }
        })
    }

    const siteMenu = document.querySelector('#site-menu')
    if (siteMenu) {

    }

    const menuButton = document.querySelector('#menu-button')
    if (menuButton && siteMenu) {
        menuButton.addEventListener('click', () => {
            siteMenu.classList.toggle('open')
        })
    }
}

export default SiteMenu
